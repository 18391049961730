@tailwind base;
@tailwind components;
@tailwind utilities;

.progress-bar {
  background: repeating-linear-gradient(
    -45deg,
    rgba(255,255,255,0.2) 0px,
    rgba(255,255,255,0.2) 10px,
    transparent 10px,
    transparent 20px
  );
  background-size: 200px 200px;
  background-color: green;
}